.card {
  @apply border border-gray-300 rounded-lg bg-white;
  
  .header {
    @apply px-6 py-4 border-b border-gray-300;
  }
  
  .content {
    @apply px-6 py-4;
  }
}