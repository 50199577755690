@import './assets/fonts/index.scss';
@import './scss';

html, body, #root {
  height: 100%;
  @apply bg-gray-100;
}

* {
  @apply text-gray-700 font-normal;
}

/** Button-like elements */
button, select {
  @apply px-3 py-1 rounded-md;
}

select {
  @apply bg-gray-50 border border-gray-300 py-2 px-4 rounded-md;
}

// button {
//   @apply bg-blue-600 text-white rounded-full font-medium py-2 px-4 tracking-wide text-sm transition-all;
  
//   &:active {
//     @apply scale-95;
//   }
  
//   &:disabled {
//     @apply bg-blue-300;
//   }
  
//   &.secondary {
//     @apply border border-blue-600 bg-white text-blue-600;
//   }
// }

a {
  @apply text-blue-600 underline underline-offset-2;
}

h1 {
  @apply text-xl font-semibold text-gray-700;
}

h3 {
  @apply text-lg font-medium text-gray-700;
  
  & + .subheader {
    @apply font-medium text-sm text-gray-400;
  }
}

h4 {
  @apply text-base font-medium text-gray-700;
}

h5 {
  @apply text-sm font-medium text-gray-400;
}

small {
  display: inline-block;
  @apply text-sm leading-5;
}