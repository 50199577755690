.button {
  @apply rounded-full py-2 px-4 transition-all font-medium text-sm;
  
  &:active {
    @apply scale-95;
  }
  
  &:disabled {
    @apply bg-blue-300;
  }
  
  &.contained {
    @apply bg-blue-600 text-white;
  }
  
  &.outlined {
    @apply border-2 border-blue-600 bg-white text-blue-600;
  }
}